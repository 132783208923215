<script>
	import AppleLogo from 'svelte-material-icons/Apple.svelte';
	import GooglePlayLogo from 'svelte-material-icons/GooglePlay.svelte';
	import Link from '$lib/core/Link.svelte';
	import BetaIcon from 'svelte-material-icons/Beta.svelte';

	export let type = 'apple';
	export let href= '';
</script>

<Link href={href} target="_blank" style="text-decoration:none;">
	<div
		class="flex items-center gap-15"
		style="background: black;color:white;padding: 8px 28px;border-radius:4px;"
	>
		<div>
			{#if type === 'apple'}
				<AppleLogo size={34} />
			{:else if type === 'firebase'}
				<BetaIcon  size={32}/>
			{:else}
				<GooglePlayLogo size={32} />
			{/if}
		</div>
		<div>
			<div style="font-size:12px;line-height:12px;">Download on the</div>
			<div style="font-weight:600;">
				{#if type === 'apple'}
					App Store
				{:else if type === 'firebase'}
					Beta Release
				{:else}
					Google Play
				{/if}
			</div>
		</div>
	</div>
</Link>
