<script>
	export let href = '';
</script>

{#if href}
	<a {href} {...$$restProps}>
		<slot />
	</a>
{:else}
    <slot />
{/if}
