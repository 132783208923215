<script>
	import Section from '$lib/core/Section.svelte';
	import Button from '$lib/Button.svelte';
	import { updateUser } from '$lib/userAPI';
	import { userProfile } from '$lib/userProfileStore';
	import { dev } from '$app/environment';
	import { captureException } from '@sentry/sveltekit';

	export let trainer = {};

	async function handleSwitchTrainer() {
		if (trainer?.id) {
			try {
				await updateUser({
					id: $userProfile?.id,
					payload: {
						trainerId: trainer?.id
					}
				});

				window.location.reload();
			} catch (e) {
                captureException(e);
            }
		}
	}
</script>

<Section>
	<div>Are you sure you want to switch coach ?</div>
	<div>
		New Trainer: {trainer?.fName}
		{trainer?.lName}
	</div>

	<div style="margin-top:50px;">
		{#if dev}
			<Button onClick={handleSwitchTrainer}>Switch Trainer</Button>
		{:else}
			Coming Soon...
		{/if}
	</div>
</Section>
