<script>
	import { page } from '$app/stores';
	import AppBadge from '$lib/AppBadge.svelte';
	import Container from '$lib/Container.svelte';
	import ContentBody from '$lib/ContentBody.svelte';
	import GettingStartedSection from '$lib/core/GettingStartedSection.svelte';
	import Header from '$lib/core/Header.svelte';
	import Section from '$lib/core/Section.svelte';
	import Tooltip from '$lib/core/Tooltip.svelte';
	import { fetchUser, updateUser } from '$lib/userAPI';
	import { updateUserProfile, userProfile } from '$lib/userProfileStore';
	import Crypto  from 'crypto-js';
	import { onMount } from 'svelte';
	import AppleLogo from 'svelte-material-icons/Apple.svelte';
	import ChangeCoachSection from '$lib/coachSection/ChangeCoachSection.svelte';
	import { env } from '$env/dynamic/public';
	import SavingOverlay from '$lib/core/SavingOverlay.svelte';
	import { captureException } from '@sentry/sveltekit';

	export let isHome = false;

	let isLoading = true;
	let isSaving = false;
	let isNotFound = false;

	let trainer;

	onMount(async () => {
		let trainerId = String($page.url.searchParams.get('t') || '');

		if (!trainerId) {
			isLoading = false;
			return;
		}

		try {
			trainerId = decodeURIComponent(
				Crypto?.AES.decrypt(trainerId, env.PUBLIC_ADD_ATHLETE_PUBLIC_KEY).toString(Crypto.enc.Utf8)
			);

			if (!trainerId) {
				isNotFound = true;
			}
		} catch (e) {
			console.log(e);
			isNotFound = true;
			return;
		}

		trainer = await fetchUser(trainerId);

		isLoading = false;
	});

	async function handleRegisterTrainer() {
		isSaving = true;

		try {
			let payload = {
				trainerId: trainer?.id
			};

			await updateUser({
				id: $userProfile?.id,
				payload
			});

			updateUserProfile(payload);
		} catch (e) {
			captureException(e);
		}

		isSaving = false;

		/*
		await updateUser({
				id: profile?.id,
				payload
			});
			*/
	}
</script>

<ContentBody {isLoading} {isNotFound}>
	<Header title="SculptX App" />

	<Container>
		{#if !$userProfile.trainerId}
			{#if trainer?.id}
				<GettingStartedSection
					title="Let's get started!"
					desc={`Let's get you started by registering with ${trainer?.fName || ''} ${
						trainer?.lName || ''
					}`}
					buttonLabel="Register Now"
					onStart={handleRegisterTrainer}
				/>
			{:else}
				<GettingStartedSection
					title="No Coach"
					desc="You don't have a coach registered yet. Please ask your coach for an invitation link."
				/>
			{/if}
		{:else if $userProfile?.trainerId != trainer?.id && !isHome}
			<ChangeCoachSection {trainer} />
		{:else}
			<Section>
				<div style="padding: 40px 0;">
					<div style="text-align:center;">
						<div style="font-size:18px;font-weight:500;">SculptX</div>
						<div style="font-size:24px;margin-top:5px;">
							with
							<span style="font-weight:600;color: var(--color-primary)">Benny Luk</span>
						</div>
						<div style="margin-top:10px;">Install the app to being training.</div>
					</div>

					<div class="flex justify-center gap-15" style="margin-top: 30px;">
						<Tooltip title="Coming soon!">
							<AppBadge />
						</Tooltip>
						<Tooltip title="Coming soon!">
							<AppBadge type="google" />
						</Tooltip>
						<AppBadge
							type="firebase"
							href={`https://appdistribution.firebase.dev/i/9e139e0d8c8c37cc`}
						/>
					</div>
				</div>
			</Section>
		{/if}
	</Container>
</ContentBody>

{#if isSaving}
	<SavingOverlay />
{/if}
